<template>
  <div class="exception" v-if="!isProcessing">
    <exception-menu v-for="item in items" :key="item.title" :item="item" />
  </div>
</template>

<script>
import ExceptionMenu from '@/components/common/menu.vue'

export default {
  name: 'exception',
  components: { ExceptionMenu },
  data () {
    return {
      items: [
        { title: '例外ユーザーの追加', content: '例外ユーザーを追加できます。', to: 'exception_add' },
        { title: '例外ユーザーの編集・削除', content: '例外ユーザーの編集や削除ができます。', to: 'exception_edit' },
        { title: '例外ユーザーのタグ管理', content: '例外ユーザーに付与するタグの追加や削除ができます。', to: 'exception_tag' }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.exception {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: 0 $padding_width $padding_height;
  margin: $header_height auto 0 auto;
}
</style>
